import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { CheckTicketResponse } from './TicketCheckerResponseMatrix';
import Error from '../enter/error';
import PageLoading from '../widgets/page_loading';
import { isBrowser } from '../../utils';
import isApp from '../../utils/isApp';
import sendTicketEntryMetrics from '../../utils/sendTicketEntryMetrics';
import sendTicketCheckerMetrics from '../../utils/sendTicketCheckerMetrics';

const TheFlow = ({ user, entry, scratchTicket, config, revealToken, actions, location }) => {
    //const [requestMade, setRequestMade] = useState(false); //? not used, keep it for later
    const entryMethod = isBrowser() ? window.history.state?.entry_method : null;

    useEffect(() => {
        clearIfSuccess();
    }, [scratchTicket]);

    useEffect(() => {
        actions.revealTokenActions.getRevealToken();
    }, [user]);

    const toggleTicketEntry = () => {
        return entry.last_entry.TicketDetails.IsPromotionEligible === 'Yes';
    };

    const enterLastTicket = () => {
        if (entry.last_entry) {
            // setRequestMade(true); //? not used, keep it for later
            actions.scratchTicketActions.enterScratchTicket({
                ticket1_1_1: entry.last_entry.TicketDetails.TicketData,
                entry_method: entryMethod,
            });

            // ticket entry metrics
            let wasScanned = entryMethod ? true : false;
            sendTicketEntryMetrics(isApp(), wasScanned, scratchTicket.success);
        }
    };

    const clearIfSuccess = async () => {
        if (scratchTicket.success) {
            navigate('/ticket_checker/post_claim', {
                state: { returnURL: '/ticket_checker/post_check' },
            });
        }
    };

    const onNewTicket = e => {
        e.preventDefault();

        actions.entryActions.reset();
        actions.drawTicketActions.reset();
        actions.scratchTicketActions.reset();
        actions.fastPlayTicketActions.reset();
        actions.checkTicketActions.reset();

        navigate('/ticket_checker');
    };

    const giveResults = () => {
        if (entry.last_entry) {
            const lent = entry.last_entry;

            // ticket checker metrics
            let wasScanned = entryMethod ? true : false;
            sendTicketCheckerMetrics(isApp(), wasScanned, lent.TicketDetails.TicketStatus);

            return (
                <div className={'scbz-content-area text-center ' + lent.TicketDetails.TicketStatus}>
                    <CheckTicketResponse
                        ticketCheckInfo={lent.TicketDetails}
                        ticketResponseCode={lent.ResultCode}
                        eligible={toggleTicketEntry}
                        enterLastTicket={enterLastTicket.bind(this)}
                        isUserLoggedIn={user.loggedIn}
                        config={config}
                        onNewTicket={onNewTicket}
                    />
                    <div className='ticket-alert'>
                        <Error ticket={scratchTicket} actions={actions} />
                        <PageLoading loadedAction='enterScratchTicket' hasIcon='true' />
                    </div>
                </div>
            );
        }
    };

    return <>{giveResults()}</>;
};

export default connect(mapStateToProps, mapDispatchToProps)(TheFlow);
