const sendTicketCheckerMetrics = (isApp, wasScanned, ticketResponse) => {
    let metricsPayload = {
        checkFromApp: isApp,
        checkScanned: wasScanned,
        checkResponse: ticketResponse,
    };

    if (window.gtag) {
        console.log('sending metrics: entry', metricsPayload);
        window.gtag('event', 'entry', metricsPayload);
    } else {
        console.error('window.gtag unavailable');
    }

    return;
};

export default sendTicketCheckerMetrics;
