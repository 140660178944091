import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import Layout from '../../components/layouts/Layout';
import SEO from '../../components/seo';

import TheFlow from '../../components/ticket_checker/the_flow';
import { getText } from '../../utils/cms_copy_provider';

const PostCheck = ({ user, entry, config, notification, revealToken, actions, location }) => {
    return (
        <Layout user={user} config={config} notification={notification} actions={actions}>
            <SEO title='PostCheck' />
            <div className='ticket_checker post-check'>
                <div className='container-fluid primary-container'>
                    <div className='vip-stripe'>
                        <a href='/ticket_checker' className='w-100 vip-link'>
                            {getText('ticket_checker_title')} <span className='fa fa-chevron-left float-right' aria-hidden='true'></span>
                        </a>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <TheFlow />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCheck);
