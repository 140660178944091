import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { getText, getHTML } from '../../utils/cms_copy_provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoginUrl } from '../wrapper/login';

import '../../utils/fontAwesome';

const ticketCheckInfoShape = {
    ExtendedErrors: PropTypes.any, // need more info for what this can be
    Message: PropTypes.string,
    ResultCode: PropTypes.any, // not sure if this is confined to integers
    TicketDetails: PropTypes.shape({
        CashAmount: PropTypes.number,
        GameId: PropTypes.any, // not sure if this is confined to integers
        GameName: PropTypes.string,
        GameType: PropTypes.oneOf(['Draw', 'Instant']).isRequired,
        IsPromotionEligible: PropTypes.string,
        Prizes: PropTypes.arrayOf(
            PropTypes.shape({
                Type: PropTypes.string.isRequired,
                ValueAmount: PropTypes.number.isRequired,
            })
        ),
        TicketData: PropTypes.string,

        // TicketStatus should be changed to oneOf for the
        // known values but i don't know them yet
        TicketStatus: PropTypes.string,
    }),
};

const EnterTicket = ({ eligible, enterLastTicket, isUserLoggedIn, config }) => {
    if (eligible()) {
        if (isUserLoggedIn) {
            return (
                <button onClick={enterLastTicket} className='btn btn-primary mt-2 check-another'>
                    {getText('ticket_checker_enter_ticket_for_rewards')}
                </button>
            );
        } else {
            return (
                <Link to={LoginUrl(config, '/ticket_checker')} className='btn btn-primary mt-2 check-another'>
                    {getText('ticket_checker_log_in_for_rewards')}
                </Link>
            );
        }
    } else {
        return null;
    }
};

export const NoCurrentPromotions = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToRetail
            message={getText('ticket_checker_no_current_promotions')}
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

NoCurrentPromotions.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const ErrorSubmitting = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToRetail
            message={getText('ticket_checker_error_submitting')}
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

ErrorSubmitting.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const TicketSubmittedNotValid = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToRetail
            message={getText('ticket_checker_ticket_submitted_not_valid')}
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

TicketSubmittedNotValid.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const DrawResultNotIn = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToRetail
            message='Draw Results Not In'
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

DrawResultNotIn.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const DrawRemains = ({ eligible, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToRetail
            message='Not Yet a Winner, Draws Remain'
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

DrawRemains.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const PreviouslyPaid = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToRetail
            message='Ticket was a Winner, Has Already been Paid'
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

PreviouslyPaid.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const PreviouslyClaimed = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToRetail
            message='Ticket was a Winner, Has Already been Claimed'
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

PreviouslyClaimed.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

export const InvalidTicket = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToRetail
            message='Not a Valid Ticket Number'
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

InvalidTicket.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

// <!-- not a winner -->
export const NotAWinner = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <div className='overlay not-winner'>
            {getHTML('ticket_checker_not_a_winner')}

            <EnterTicket eligible={eligible} enterLastTicket={enterLastTicket} isUserLoggedIn={isUserLoggedIn} config={config} />

            <p className='have-more'>{getText('ticket_checker_have_more_tickets')}</p>
            <a href='#' onClick={onNewTicket} className='check-another btn btn-primary'>
                {getText('ticket_checker_check_another_ticket')}
            </a>
        </div>
    );
};

// <!-- winner -->
export const Winner = ({
    ticketCheckInfo: { PrizeDescription, GameType, CashAmount, Prizes },
    eligible = false,
    enterLastTicket,
    isUserLoggedIn,
    config,
    onNewTicket,
}) => {
    const amountSwitch = (GameType, CashAmount, PrizeDescription, Prizes) => {
        const drawType = GameType && GameType.toLowerCase() === 'draw';

        let amountInDollars;
        if (drawType) {
            amountInDollars = CashAmount / 100;
        } else {
            const cashPrize = Array.isArray(Prizes) && Prizes.filter(prize => prize['Type'].toLowerCase() === 'cash').pop();

            const valueAmount = cashPrize && cashPrize['ValueAmount'] ? cashPrize['ValueAmount'] : CashAmount;

            amountInDollars = valueAmount / 100;
        }

        // TODO
        // change this over to use i18n values?
        const locale = {
            str: 'en-US',
            country: 'USD',
        };
        const localeDisplay = amountInDollars.toLocaleString(locale.str, {
            style: 'currency',
            currency: locale.country,
        });

        if (CashAmount > 60000) {
            return <>{getHTML('ticket_checker_winner_claim_reward')}</>;
        } else if (CashAmount > 0) {
            return (
                <>
                    <h2>
                        Congratulations!
                        <span className='winner'>{`WINNER ${localeDisplay}!`}</span>
                    </h2>
                    <p className='directive'>{getText('ticket_checker_winner_shown_amount')}</p>
                </>
            );
        } else {
            const toRetail = () => {
                if (CashAmount !== -1) {
                    return <p className='directive'>{getText('ticket_checker_to_retail')}</p>;
                }
            };

            const prizeDesc = PrizeDescription => {
                if (PrizeDescription != null) {
                    return <span className='winner'>{PrizeDescription}</span>;
                }
            };

            return (
                <>
                    <h2>
                        {getText('ticket_checker_congratulations')}
                        {prizeDesc(PrizeDescription)}
                    </h2>
                    {toRetail()}
                </>
            );
        }
    };

    return (
        <div className='overlay winner'>
            <FontAwesomeIcon icon={['fas', 'thumbs-up']} size='2x' />

            {amountSwitch(GameType, CashAmount, PrizeDescription, Prizes)}

            <EnterTicket eligible={eligible} enterLastTicket={enterLastTicket} isUserLoggedIn={isUserLoggedIn} config={config} />

            <p className='have-more'>{getText('ticket_checker_have_more_tickets')}</p>
            <a href='#' onClick={onNewTicket} className='check-another btn btn-primary'>
                {getText('ticket_checker_check_another_ticket')}
            </a>
        </div>
    );
};

// <!-- take to retailer -->
export const ToRetail = ({ message, eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <div className='overlay to-retailer'>
            <h2>{message}</h2>
            <EnterTicket eligible={eligible} enterLastTicket={enterLastTicket} isUserLoggedIn={isUserLoggedIn} config={config} />
            <p className='have-more'>{getText('ticket_checker_have_more_tickets')}</p>
            <a href='#' onClick={onNewTicket} className='check-another btn btn-primary'>
                {getText('ticket_checker_check_another_ticket')}
            </a>
        </div>
    );
};

// this component is used as a fallback when
// some value comes over that we haven't defined
export const FallbackComponent = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToRetail
            message='Take ticket to PA Lottery Retailer'
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

// feels weird defining it but this will be passed
// to FallbackComponent _and ignored_
FallbackComponent.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

// <!-- take to lottery -->
export const ToLottery = ({ message, eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <div className='overlay to-lottery'>
            <h2>{message}</h2>
            <EnterTicket eligible={eligible} enterLastTicket={enterLastTicket} isUserLoggedIn={isUserLoggedIn} config={config} />
            <p className='have-more'>{getText('ticket_checker_have_more_tickets')}</p>
            <a href='#' onClick={onNewTicket} className='check-another btn btn-primary'>
                {getText('ticket_checker_check_another_ticket')}
            </a>
        </div>
    );
};

export const FileClaimAtLottery = ({ eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    return (
        <ToLottery
            message='File Claim at Lottery with Original Ticket.'
            eligible={eligible}
            enterLastTicket={enterLastTicket}
            isUserLoggedIn={isUserLoggedIn}
            config={config}
            onNewTicket={onNewTicket}
        />
    );
};

FileClaimAtLottery.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};

// CheckTicketResponse will be passed the result of a
// check ticket call.  It will map the TicketStatus
// property to one of the above response components
// or fall back to FallbackComponent if we don't have a response
export const CheckTicketResponse = ({ ticketCheckInfo = {}, ticketResponseCode, eligible = false, enterLastTicket, isUserLoggedIn, config, onNewTicket }) => {
    const components = {
        WINNER: Winner,
        NOT_A_WINNER: NotAWinner,
        RESULTS_NOT_IN: NoCurrentPromotions,
        PAID: NoCurrentPromotions,
        EXPIRED: NoCurrentPromotions,
        ON_HOLD: NoCurrentPromotions,
        INSTANT_WINNER_DRAWS_REMAINING: NoCurrentPromotions,
        CLAIMED: NoCurrentPromotions,
        NOT_WINNER_DRAWS_REMAINING: DrawRemains,
        CLAIM_AT_LOTTERY: NoCurrentPromotions,
        CANCELED: NoCurrentPromotions,
        ALREADY_PAID: NoCurrentPromotions,
        TICKET_COMPLETELY_EXPIRED: NoCurrentPromotions,
        UNABLE_TO_CASH_CLAIM_AMT_TOO_BIG_TOO_LOW: NoCurrentPromotions,
        NOT_ABLE_TO_PAY: NoCurrentPromotions,
        ALREADY_CLAIMED: NoCurrentPromotions,
        CANNOT_PAY_YET: NoCurrentPromotions,
        CALL_HOTLINE: NoCurrentPromotions,
        NOT_A_WINNER_YET: NoCurrentPromotions,
        PAYMENT_DEFERRED: NoCurrentPromotions,
        PAID_BY_YOU: NoCurrentPromotions,
        PAID_BY_OTHER: NoCurrentPromotions,
        CLAIMED_BY_YOU: NoCurrentPromotions,
        CLAIMED_BY_OTHER: NoCurrentPromotions,
        ERROR_TERMINAL_IN_TRAINING_MODE: ErrorSubmitting,
        EXCEEDS_CASHING_LIMIT: NoCurrentPromotions,
        TICKET_TOO_OLD: NoCurrentPromotions,
        TICKET_EXPIRED: NoCurrentPromotions,
        TICKET_NOT_ON_FILE: TicketSubmittedNotValid,
        TICKET_PREVIOUSLY_CANCELLED: NoCurrentPromotions,
        DRAW_NOT_CLOSED_YET: NoCurrentPromotions,
        VALIDATE_MANUALLY: NoCurrentPromotions,
        PAID_BY_EFT: NoCurrentPromotions,
        NOT_CLAIMED_YET: NoCurrentPromotions,
        VALIDATION_REJECTED_CANCELLED: TicketSubmittedNotValid,
        HIGH_WINNER: NoCurrentPromotions,
        WINNER_AGENT_CANNOT_PAY: NoCurrentPromotions,
        CONTACT_LOTTERY: NoCurrentPromotions,
        MUST_GET_FREE_TICKET_FIRST: NoCurrentPromotions,
        TOO_MANY_TRANSACTIONS_IN_SESSION: NoCurrentPromotions,
        INCORRECT_CLAIM_CARD_SESSION: NoCurrentPromotions,
        INVALID_TICKET_SECURITY_INFO: TicketSubmittedNotValid,
        INVALID_NOT_A_SELLING_AGENT: ErrorSubmitting,
        NOT_A_SELLING_RETAILER: ErrorSubmitting,
        SERVICE_DISABLED: ErrorSubmitting,
        FUNCTION_SUPPRESSED: ErrorSubmitting,
        SCANNING_ERROR: ErrorSubmitting,
        UNKNOWN: ErrorSubmitting,
        DUPLICATE: NoCurrentPromotions,
        NOT_ELIGIBLE_FOR_PROMOTION: NoCurrentPromotions,
        VALID_TICKET: NotAWinner,
        INVALID_TICKET: NotAWinner,
        DRAW_HAS_NOT_OCCURRED_MORE_DRAWS_REMAIN: DrawResultNotIn,
        NOT_YET_A_WINNER_DRAWS_REMAIN: DrawRemains,
        PAYOUT_NOT_FINAL_TRY_LATER: DrawResultNotIn,
        DRAW_HAS_NOT_OCCURRED: DrawResultNotIn,
        PREVIOUSLY_PAID: PreviouslyPaid,
        PREV_PAID_BY_YOU: PreviouslyPaid,
        'FILE_CLAIM_@_LOTTERY_WITH_ORIGINAL_TKT': FileClaimAtLottery,
        INVALID_VIRN: InvalidTicket,
        NOT_VALID: InvalidTicket,
        NOT_AVAILABLE: FallbackComponent,
    };

    const getMessageComponent = (checkInfo, resCode) => {
        return components[checkInfo.TicketStatus.toUpperCase()] ? components[checkInfo.TicketStatus.toUpperCase()] : FallbackComponent;
    };

    const Message = getMessageComponent(ticketCheckInfo, ticketResponseCode);

    return (
        <>
            <Message
                ticketCheckInfo={ticketCheckInfo}
                ticketResponseCode={ticketResponseCode}
                eligible={eligible}
                enterLastTicket={enterLastTicket}
                isUserLoggedIn={isUserLoggedIn}
                config={config}
                onNewTicket={onNewTicket}
            />
        </>
    );
};

CheckTicketResponse.propTypes = {
    ticketCheckInfo: PropTypes.shape(ticketCheckInfoShape),
};
