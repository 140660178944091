import promotion from '../../../promotion_config';
import isBrowser from '../../utils/isBrowser';
import { useEffect, useState } from 'react';

const LoginUrl = (config, path = `${location.pathname}`) => {
    let myLocation = isBrowser() ? location.protocol + '//' + location.host + path : '';

    const [configLoginUrl, setConfigLoginUrl] = useState(config.config.login_host_uri);

    useEffect(() => {
        setConfigLoginUrl(config.config.login_host_uri);
    }, [config.config]);

    // remove any trailing slashes
    myLocation = myLocation.replace(/\/$/, '');

    const defaultLoginUrl = promotion.getLoginLink(myLocation, myLocation);

    const loginUrl = isBrowser() && configLoginUrl ? `${configLoginUrl}?PtaUrl=${myLocation}&Redirect=${myLocation}` : defaultLoginUrl;

    return loginUrl;
};

const LogoutClick = actions => {
    return async e => {
        await actions.userActions.logout();
        // reinstate this redirect once the link works
        // navigate(
        //     'https://www-u69.nyxop.net/VIP-Players-Club/Login.aspx?logout=1'
        // );
        // e.preventDefault();
    };
};

export { LoginUrl, LogoutClick };
